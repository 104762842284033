import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDebouncedState, usePagination} from "@mantine/hooks";
import {Box, Flex, Paper, Text, TextInput} from "@mantine/core";
import {DateInput} from "@mantine/dates";
import {useGetBookingsQuery} from "../../../api/booking";
import OrdersTable from "../orders/OrdersTable";
import PageContainer from "../../../components/PageContainer";
import EventInputSelect from "../../../components/EventInputSelect";
import {useGetEventsQuery} from "../../../api/event";

const OrdersInternet = () => {
    const {t} = useTranslation();
    const [page, setPage] = useState(1);
    const [selectedEvent, setSelectedEvent] = useState<number | undefined>();
    const [search, setSearch] = useDebouncedState('', 200);
    const page_size = 10;
    const {data: events = {results: []}} = useGetEventsQuery({
        page: 1,
        page_size: 9999,
        ordering: "-start"
    });
    const {data, isFetching} = useGetBookingsQuery({
        status: ["sold", "refunded"],
        page_size,
        page,
        search,
        event: selectedEvent,
        mode: ["customer", "customer_long"]
    }, {
        refetchOnMountOrArgChange: true
    })

    const totalPages = Math.ceil((data?.count || 0) / page_size)
    const pagination = usePagination({
        total: totalPages,
        initialPage: 1,
        page: page,
        onChange: setPage
    });

    function onChangeEvent(v: string) {
        setSelectedEvent(Number(v));
    }

    return (
        <PageContainer>
            <Text mt={0} mb={20} size={"xl"}>{t("orders.title")}</Text>
            <Paper p={10}>
                <Flex justify={"space-between"} mr={20}>
                    <Flex>
                        <DateInput placeholder={t("common.from")}/>
                        <DateInput placeholder={t("common.to")} ml={15}/>
                        <Box style={{marginLeft: 15}}>
                            <EventInputSelect
                                onChange={onChangeEvent}
                                value={String(selectedEvent)}
                                data={events.results}
                            />
                        </Box>
                    </Flex>
                    <Box w={'30%'}>
                        <TextInput
                            inputMode={"search"}
                            style={{width: "100%"}}
                            placeholder={t("common.search")}
                            ml={15}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </Box>
                </Flex>
            </Paper>
            <OrdersTable isFetching={isFetching} data={data} page={page} totalPages={totalPages}
                         setPage={pagination.setPage} type={'internet_orders'}/>
        </PageContainer>
    )
}

export default OrdersInternet;