import React from "react";
import { Box, Button, Divider, Drawer, Flex, Modal, Paper } from "@mantine/core";
import { useConfirmationModal } from "../../hooks/useConfirmationModal";
import { Pencil } from "lucide-react";
import { useTranslation } from "react-i18next";
import classes from "./CrudModal.module.css";

interface IProps {
    opened: boolean;
    type?: "drawer" | "modal";
    isUpdate?: boolean;
    inEdit?: boolean;
    children: React.ReactNode;
    formId: string;
    title?: string;
    withDelete?: boolean,
    hideActions?: boolean,

    onClose(): void;

    onDelete(): void;

    setEdit?: (isEdit: boolean) => void;
}

const CrudModal = (props: IProps) => {
    const {
        opened,
        onClose,
        onDelete,
        children,
        isUpdate,
        formId,
        title,
        withDelete,
        hideActions,
        setEdit = () => {
        },
        inEdit = true,
        type = "modal"
    } = props;
    const [ConfirmModal, confirmDelete] = useConfirmationModal({});
    const { t } = useTranslation();

    async function onClickConfirm() {
        const res = await confirmDelete();
        if (res) await onDelete();
    }

    const Actions = () => (
        <Paper style={{ display: "flex", justifyContent: "flex-end" }}>
            {(isUpdate && !withDelete) && (
                <Button onClick={() => setEdit(false)} variant={"outline"}>
                    {t("common.cancel")}
                </Button>
            )}
            {withDelete && (
                <Button color={"red.5"} variant={"outline"} onClick={onClickConfirm}>
                    {t("common.remove")}
                </Button>
            )}
            <Button ml={5} type={"submit"} form={formId}>
                {isUpdate ? t("common.update") : t("common.save")}
            </Button>
        </Paper>
    );

    return (
        <div>
            {type === "modal" ? (
                <Modal
                    opened={opened}
                    onClose={onClose}
                    centered
                    size={"lg"}
                    title={title}
                    zIndex={450}
                >
                    <Divider mb={20} />
                    {children}
                    {!hideActions && (
                        <>
                            <Divider mt={20} mb={10} />
                            <Actions />
                        </>
                    )}
                </Modal>
            ) : (
                <Drawer
                    title={title}
                    opened={opened}
                    onClose={onClose}
                    size={"670px"}
                    position={"right"}
                    zIndex={450}
                    classNames={{
                        inner: classes.drawerRoot,
                        overlay: classes.overlay
                    }}
                    styles={{
                        header: {
                            backgroundColor: "var(--_button-bg,var(--button-bg,var(--mantine-primary-color-filled)))",
                            color: "#ecf0f1",
                            padding: "15px",
                            marginBottom: "10px"
                        },
                    }}
                >
                    {(isUpdate && !inEdit) && (
                        <Flex justify={"flex-end"} mb={10}>
                            <Button color={"red.5"} variant={"outline"} size={"xs"} onClick={onClickConfirm}>
                                {t("common.remove")}
                            </Button>
                            <Button onClick={() => setEdit(true)} ml={10} size={"xs"}
                                    variant={"outline"}
                                    rightSection={<Pencil size={15} />}>
                                {t("common.edit")}
                            </Button>
                        </Flex>
                    )}
                    {children}
                    {inEdit && (
                        <Box mt={20} mb={10}>
                            <Actions />
                        </Box>
                    )}
                </Drawer>
            )}
            <ConfirmModal />
        </div>
    );
};

export default CrudModal;
